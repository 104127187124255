import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule,CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  showReviews: boolean = true;
  showData:boolean = true;
  constructor(public router: Router,) {
  }
  ngOnInit(): void {
    const locationValue = localStorage.getItem('locations');
    this.showReviews = !(locationValue === '1');
    this.showData = !(locationValue === '1')
  }

  reviews() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/companies-reviews']);
    }
  };

  locations() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/locations']);
    }
  };


  panel() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/panel']);
    }
  };
  logout(){
    localStorage.removeItem('access_token');
    localStorage.removeItem('locations');
    localStorage.removeItem('reviews');
    localStorage.removeItem('data');
    localStorage.setItem('pageReloaded','off');
    this.router.navigate(['']);
  }

}
