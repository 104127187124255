            <!-- SIDEBAR -->
            <div class="col-2 bg-dark position-fixed min-vh-100 px-2" id="sticky-sidebar">
                <a href="" class="text-center text-white text-decoration-none mt-1 d-flex align-items-center mt-4 mt-sm-0" style="margin-left: 35%" 
                    role="button">
                    <span class="fs-4 py-3" id="logo"><img class="logo-sidebar" src="../../../assets/img/logo.png"
                            alt="logo top local"></span>
                </a>
    
                <hr>
    
                <ul class="nav nav-pills flex-column mt-4 mt-sm-0" id="menu">
                    <li class="nav-item py-2 py-sm-0" *ngIf="showReviews">
                        <button (click)="reviews()"
                            class="btn btn-dark text-light my-2 link-hover">
                            <i class="fa-solid fa-ranking-star fa-l"></i>
                            <span class="fs-4 ms-2 d-none d-sm-inline">Reviews </span>
                        </button>
                    </li>
    
                    <li class="nav-item py-2 py-sm-0">
                        <button (click)="locations()" class="btn btn-dark text-light my-2 link-hover"
                            aria-current="page">
                            <i class="fa-solid fa-location-dot fa-l"></i>
                            <span class="fs-4 ms-2 d-none d-sm-inline ms-2">Ubicaciones</span>
                        </button>
                    </li>
                    <li class="nav-item py-2 py-sm-0" *ngIf="showData">
                        <button class="btn btn-dark text-light btn-data" (click)="panel()"><i class="fa-solid fa-chart-line"></i><span class="text-panel">Data</span></button>
                    </li>
                    <li  class="nav-item py-2 py-sm-0">
                        <button (click)="logout()" class="btn btn-dark text-light my-2 link-hover" aria-current="page">
                            <i class="fa-solid fa-sign-out"></i>
                            <span class="fs-4 ms-2 d-none d-sm-inline ms-2 fst-italic cursor-pointer">Salir</span>
                        </button>
                    </li>
                </ul>
            </div>
            <!-- END SIDEBAR -->