import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../../service/locations.service';
import { LocationList } from './locations-edit.model';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
import { Router } from '@angular/router';
import {RouterModule} from '@angular/router';

/* eslint-disable */
@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss'],
  // standalone: true,
  // imports: [RouterModule],
  encapsulation: ViewEncapsulation.None  // Desactivar el encapsulamiento de estilos
})
export class LocationEditComponent implements OnInit {
  @Output() saveLocation = new EventEmitter<any>();
  locationInfo: any;
  locationDetail: LocationList;
  regularHours: any[] = [];
  regularHoursByTime: any = {};
  selectedDaysSummary: { [key: string]: string } = {}; // Nueva propiedad para mantener el resumen de días seleccionados.
  allDaysOfWeek: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  schedule: { [day: string]: { openTime: string, closeTime: string } } = {};


  constructor(private route: ActivatedRoute, private locationsService: LocationsService, private drawStats: DrawStatsService, public router: Router,) {
    this.locationInfo = this.getLocationEditDefault();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const locationId = params.locationId;
      this.chargeLocation(locationId);
    });
  }
  //--------------------------------------CARGAR DATOS----------------------------------------
  public chargeLocation(id: string) {
    this.locationsService.getLocationById(id)
      .subscribe(response => {
        this.fillLocationEditData(response);
        this.translateDaysOfWeek(); // Llama a la función para traducir los días de la semana
        this.updateAllSelectedDaysSummaries();
        this.sortHours();
      });
  }

  //--------------------------------------FIJAR DATOS------------------------------------------
  // funcion para "fijar los datos"
  public fillLocationEditData(dataPetiton: any) {
    this.locationInfo.codigo_tienda = dataPetiton.codigo_tienda;
    this.locationInfo.nombre = dataPetiton.nombre;
    this.locationInfo.direccion = dataPetiton.direccion;
    this.locationInfo.provincia = dataPetiton.provincia;
    this.locationInfo.localidad = dataPetiton.localidad;
    this.locationInfo.id = dataPetiton._id;
    this.locationInfo.codigo_postal = dataPetiton.codigo_postal;
    this.locationInfo.telefono = dataPetiton.telefono;
    this.locationInfo.web = dataPetiton.web;

    // Limpia el objeto regularHoursByDay antes de llenarlo
    this.regularHoursByTime = {};

    dataPetiton.regularHours.forEach((hour: any) => {
      ///// TO DELETE - FIX para poder seleccionar en caso de no llevar 0 la hour
      if (hour.openTime && hour.openTime.length==4){
        hour.openTime = '0' + hour.openTime;
      }
      if (hour.closeTime && hour.closeTime.length==4){
        hour.closeTime = '0' + hour.closeTime;
      }
      ///// TO DELETE - END
      const key = hour.openTime + '-' + hour.closeTime;
      if (!this.regularHoursByTime[key]) {
        this.regularHoursByTime[key] = [];
      }
      this.regularHoursByTime[key].push(hour.openDay);
    });
    // Traducir los días de la semana antes de proceder con más lógica que dependa de estos datos.
    this.translateDaysOfWeek();
    this.updateAllSelectedDaysSummaries();
  }

  //.----------------------------------------DIAS--------------------------------------------
  // Método para obtener los días asociados a un horario específico
  public getDaysForTime(timeKey: string) {
    return this.allDaysOfWeek.map(day => ({
      name: day,
      isSelected: this.regularHoursByTime[timeKey]?.includes(day)
    }));
  }

  public onDaySelectionChange(day: string, event: Event, timeKey: string) {
    // Casting del target del evento a HTMLInputElement para acceder a 'checked'
    const inputElement = event.target as HTMLInputElement;

    // Ahora, 'isChecked' contiene true si el checkbox está marcado, o false si no lo está.
    const isChecked = inputElement.checked;

    // Lógica para agregar o quitar el día de la lista basado en si el checkbox está marcado o no.
    const dayIndex = this.regularHoursByTime[timeKey].indexOf(day);

    if (isChecked && dayIndex === -1) {
      // Si el checkbox está marcado y el día no está en la lista, lo agregamos.
      this.regularHoursByTime[timeKey].push(day);
    } else if (!isChecked && dayIndex !== -1) {
      // Si el checkbox no está marcado y el día está en la lista, lo quitamos.
      this.regularHoursByTime[timeKey].splice(dayIndex, 1);
    }

    // Ordenar los días para asegurar que el resumen muestre el primero y último correctamente.
    this.regularHoursByTime[timeKey].sort((a, b) => this.sortDays(a, b));

    // Actualizar el resumen de días seleccionados para este timeKey.
    this.updateSelectedDaysSummary(timeKey);
  }

  //función para traducir los dias de la semana
  public translateDaysOfWeek() {
    Object.keys(this.regularHoursByTime).forEach(key => {
      this.regularHoursByTime[key].forEach((hour, index) => {
        // Traduce cada día utilizando el servicio DrawStatsService
        this.regularHoursByTime[key][index] = this.drawStats.translateDayOfWeek(hour);
      });
    });
  }

  // Función auxiliar para ordenar los días de la semana.
  public sortDays(day1: string, day2: string): number {
    const daysOrder = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    return daysOrder.indexOf(day1) - daysOrder.indexOf(day2);
  }

  public updateSelectedDaysSummary(timeKey: string) {
    const days = this.regularHoursByTime[timeKey];
    if (days && days.length > 0) {
      // Si hay días seleccionados, actualizar el resumen para mostrar el primer y último día.
      const firstDay = days[0];
      const lastDay = days[days.length - 1];
      this.selectedDaysSummary[timeKey] = days.length > 1 ? `${firstDay} - ${lastDay}` : firstDay;
    } else {
      // Si no hay días seleccionados, mostrar un mensaje predeterminado.
      this.selectedDaysSummary[timeKey] = 'Selecciona días';
    }
  }
  //-----------------------------------HORAS----------------------------------------------

  public getRegularHoursByTimeKeys() {
    return Object.keys(this.regularHoursByTime);
  }

  //función que se encarga de actualizar los resúmenes de todos los días seleccionados
  public updateAllSelectedDaysSummaries() {
    Object.keys(this.regularHoursByTime).forEach((timeKey) => {
      // basándose en los días actualmente seleccionados en regularHoursByTime[timeKey]
      this.updateSelectedDaysSummary(timeKey);
    });
  }

  //función para ordenar los horarios (mañana-tarde)
  public sortHours(): void {
    const sortedKeys = Object.keys(this.regularHoursByTime).sort((a, b) => {
      const openTimeA = a.split('-')[0];
      const openTimeB = b.split('-')[0];
      return openTimeA.localeCompare(openTimeB);
    });

    const sortedRegularHoursByTime = {};
    sortedKeys.forEach((key) => {
      sortedRegularHoursByTime[key] = this.regularHoursByTime[key];
    });
    this.regularHoursByTime = sortedRegularHoursByTime;
  }

  //método para intervalos de 15 minutes en el select de las horas
  public getHourlyIntervals(): string[] {
    let intervals = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        intervals.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return intervals;
  }

  //método que capta cuando se hace un cambio de tiempo (en el input)
  public onTimeChange(timeKey: string, event: Event, type: 'open' | 'close') {
    // Casting del target del evento a HTMLSelectElement para acceder a 'value'
    const target = event.target as HTMLSelectElement;
    const newValue = target.value;

    const timeParts = timeKey.split('-');
    const newKey = type === 'open' ? `${newValue}-${timeParts[1]}` : `${timeParts[0]}-${newValue}`;

    // Actualiza los días asociados al nuevo key y elimina el key anterior si es diferente
    if (newKey !== timeKey) {
      this.regularHoursByTime[newKey] = [...(this.regularHoursByTime[timeKey] || [])];
      delete this.regularHoursByTime[timeKey];
      this.updateAllSelectedDaysSummaries();
    }
  }
  //--------------------------------------BOTON REMOVER HORARIO------------------------------------

  //función para el botón X pueda eliminar el campo horario completo
  public removeTimeKey(timeKey: string): void {
    // Remueve la entrada del objeto regularHoursByTime usando la clave proporcionada
    delete this.regularHoursByTime[timeKey];
    // Opcionalmente, actualiza cualquier otra estructura de datos o vista que dependa de regularHoursByTime
    this.updateAllSelectedDaysSummaries(); // Por ejemplo, actualizar resúmenes si es necesario
    // Si deseas emitir algún evento o realizar alguna acción adicional después de eliminar, puedes hacerlo aquí
  }
  //--------------------------------------BOTON AÑADIR HORARIO-------------------------------------------

  // funcion para añadir una hour extra
  public addExtraHour(index: number) {
    if (!this.regularHours[index].extraHours) {
      this.regularHours[index].extraHours = [];
    }
    this.regularHours[index].extraHours.push({ openTime: '', closeTime: '' });
  }

  // funcion para eliminar una hour 
  public removeHour(index: number) {
    if (this.regularHours[index].extraHours.length > 0) {
      this.regularHours[index].extraHours.pop();
    }
  }

  // funcion para borrar una hour extra
  public removeExtraHour(dayIndex: number, hourIndex: number) {
    this.regularHours[dayIndex].extraHours.splice(hourIndex, 1);
  }

  //funcion para agregar modificaciones en los horarios
  public addSchedule() {

    var container = document.getElementById("schedules-container");

    // var scheduleDiv = document.createElement("div");
    // scheduleDiv.classList.add("horario");
    //------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------
    // Crear un identificador único basado en el timestamp actual
    const uniqueId = Date.now();

    // Crear el div de horario con un identificador único
    const scheduleDiv = document.createElement("div");
    scheduleDiv.classList.add("schedule");
    scheduleDiv.setAttribute("id", `schedule-${uniqueId}`);
    //------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------

    var details = document.createElement("details");
    details.classList.add("detail");
    var summary = document.createElement("summary");
    summary.classList.add("summary");

    summary.textContent = "Días"; // Texto que aparecerá en la caja para abrir/cerrar
    details.appendChild(summary);

    var diasCheckbox = document.createElement("div"); // Creamos un contenedor para los checkboxes de los días
    diasCheckbox.classList.add("details");

    // AQUÍ COLOCAR LA LÍNEA QUE CONVIERTE EL RESULTADO DE querySelectorAll
    var checkedCheckboxes = diasCheckbox.querySelectorAll("input[type=checkbox]:checked") as NodeListOf<HTMLInputElement>;

    var daysOfWeek = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    for (var i = 0; i < daysOfWeek.length; i++) {
      var checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.value = daysOfWeek[i];
      checkbox.id = "checkbox_" + daysOfWeek[i]; // Establecemos un ID único para cada checkbox
      var label = document.createElement("label");
      label.classList.add("label-schedule"); //classe para el label de los horarios
      label.textContent = daysOfWeek[i]; //texto para el label de los horarios en este caso los dias de la sem.
      label.setAttribute("for", "checkbox_" + daysOfWeek[i]); // Asociamos la etiqueta con el checkbox mediante el ID
      diasCheckbox.appendChild(checkbox);
      diasCheckbox.appendChild(label);
      diasCheckbox.appendChild(document.createElement("br"));
    }

    // var selectOpen = document.createElement("select");
    // selectOpen.classList.add("select");

    // var selectClose = document.createElement("select");
    // selectClose.classList.add("select");


    const selectOpen = document.createElement("select");
    selectOpen.classList.add("select", "select-openTime");
    selectOpen.setAttribute("name", `openTime-${uniqueId}`);

    const selectClose = document.createElement("select");
    selectClose.classList.add("select", "select-closeTime");
    selectClose.setAttribute("name", `closeTime-${uniqueId}`);


    for (var i = 0; i < 24; i++) {
      for (var j = 0; j < 60; j += 15) {
        var hour = i < 10 ? "0" + i : i;
        var minutes = j < 10 ? "0" + j : j;

        // ésto hace una nueva opción para la hour de apertura y cierre
        var hourOption = document.createElement("option");
        hourOption.text = hour + ":" + minutes;

        // Agrega la opción a los selectores de hour de apertura y cierre
        selectOpen.add(hourOption.cloneNode(true) as HTMLOptionElement);
        selectClose.add(hourOption.cloneNode(true) as HTMLOptionElement);
      }
    }

    // Agrega opción "cerrado" al final de los selectores de hour de apertura y cierre
    var closeOption = document.createElement("option");
    closeOption.text = "Cerrado";

    // Agrega la opción "Cerrado" a ambos selectores

    // Por ejemplo, para los selectores de hour:
    selectOpen.add(closeOption.cloneNode(true) as HTMLOptionElement);
    selectClose.add(closeOption.cloneNode(true) as HTMLOptionElement);


    var deleteBtn = document.createElement("button");
    deleteBtn.classList.add("btn-x");

    deleteBtn.textContent = "X";
    deleteBtn.onclick = function () {
      container.removeChild(scheduleDiv);
    };

    details.appendChild(diasCheckbox); // Agregamos el contenedor de checkboxes dentro del details
    scheduleDiv.appendChild(details); // Agregamos el details dentro del div horario
    scheduleDiv.appendChild(selectOpen);
    scheduleDiv.appendChild(selectClose);
    scheduleDiv.appendChild(deleteBtn);
    // scheduleDiv.appendChild(document.createTextNode("Seleccionar día: "));

    container.appendChild(scheduleDiv);

    // Evento para capturar los días seleccionados y mostrar los primeros y últimos en el resumen
    details.addEventListener("toggle", function () {
      if (!details.open) {
        var checkedCheckboxes = diasCheckbox.querySelectorAll("input[type=checkbox]:checked") as NodeListOf<HTMLInputElement>;
        var firstSelectedDay = checkedCheckboxes.length > 0 ? checkedCheckboxes[0].value : "";
        var lastSelectedDay = checkedCheckboxes.length > 0 ? checkedCheckboxes[checkedCheckboxes.length - 1].value : "";
        var selectedDays = firstSelectedDay !== lastSelectedDay ? firstSelectedDay + "-" + lastSelectedDay : firstSelectedDay;
        summary.textContent = selectedDays !== "" ? selectedDays : "Días";
      }
    });
  }

  //--------------------------------------PREPARACIÓN DE DATOS PARA ENVIAR-------------------------------------------

  //Función para preparar los datos de los horarios para enviar a la api 
  public prepareRegularHoursDataForAPI(): any[] {
    const regularHoursForAPI: any[] = [];

    Object.keys(this.regularHoursByTime).forEach(timeKey => {
      const [openTime, closeTime] = timeKey.split('-');

      this.regularHoursByTime[timeKey].forEach((day: string) => {
        const dayInAPIFormat = this.drawStats.translateDayOfWeekBack(day);

        regularHoursForAPI.push({
          openTime: openTime,
          closeTime: closeTime,
          openDay: dayInAPIFormat,
          closeDay: dayInAPIFormat,
          // Aquí no necesitamos el _id para una actualización porque el manejo es de otra manera
        });
      });
    });

    return regularHoursForAPI;
  }

  //--------------------------------------BOTÓN PARA GUARDAR INFO-------------------------------------------

  // funcion para guardar y recargar la pagina (cambios)
  public onSave() {

    // console.log("Entrando a onSave()");

    var regularHoursData = this.prepareRegularHoursDataForAPI();
    var dinamicSchedules = [];
    var scheduleDivs = document.querySelectorAll(".horario");
  
    scheduleDivs.forEach(div => {
      var daysCheckboxes = div.querySelectorAll("input[type='checkbox']:checked");
      var openTimeSelector = div.querySelector(".select-openTime") as HTMLSelectElement;
      var closeTimeSelector = div.querySelector(".select-closeTime") as HTMLSelectElement;
  
      if (!openTimeSelector || !closeTimeSelector) {
        console.error('Alguno de los selectores de tiempo no se encuentra.');
        return;
      }
  
      Array.from(daysCheckboxes).forEach(checkbox => {
        var day = (checkbox as HTMLInputElement).value;
        var openTime = openTimeSelector.value;
        var closeTime = closeTimeSelector.value;
  
        var translateDay = this.drawStats.translateDayOfWeekBack(day);
        dinamicSchedules.push({
          openDay: translateDay,
          closeDay: translateDay,
          openTime: openTime,
          closeTime: closeTime,
        });
      });
    });
  
    var combinedRegularHoursData = [...regularHoursData, ...dinamicSchedules];
  
    // Aquí descomponemos this.locationInfo y agregamos las propiedades individualmente
    var locationToUpdate = {
      nombre: this.locationInfo.nombre,
      direccion: this.locationInfo.direccion,
      provincia: this.locationInfo.provincia,
      localidad: this.locationInfo.localidad,
      codigo_postal: this.locationInfo.codigo_postal,
      telefono: this.locationInfo.telefono,
      id: this.locationInfo.id,
      web: this.locationInfo.web,
      regularHours: combinedRegularHoursData
    };
  

    // console.log('---------------DATOS A ENVIAR:----------- ', locationToUpdate);
    this.locationsService.updateLocation(locationToUpdate)
      .subscribe(
        response => {
          console.log('Datos actualizados con éxito:', response);
          this.chargeLocation(this.locationInfo.id);
        },
        error => {
          console.error('Error al actualizar los datos de la ubicación:', error);
        }
      );
  }

    // BOTÓN HACIA ATRÁS

  btnBack() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/locations']);
    }
  };
  

  //--------------------------------------INFORMACIÓN DEFAULT-------------------------------------------

  // funcion para que salgan por defecto estos datos si no se carga nada en la pagina
  public getLocationEditDefault() {
    return {
      locationInfo: {
        codigo_tienda: 'sin datos',
        nombre: 'sin datos',
        direccion: 'sin datos',
        localidad: 'sin datos',
        provincia: 'sin datos',
      }
    };
  }
}

