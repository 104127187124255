import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PanelComponent } from './components/panel/panel.component';
import { BusquedasComponent } from './components/busquedas/busquedas.component';
import { VisionadosComponent } from './components/visionados/visionados.component';
import { AccionesComponent } from './components/acciones/acciones.component';
import { ReputacionComponent } from './components/reputacion/reputacion.component';
import { AnalisisComponent } from './components/analisis/analisis.component';
import { MapComponent } from './components/map/map.component';
import { HistoricoComponent } from './components/historico/historico.component';
import { LoginTokenComponent } from './components/login-token/login-token.component';
import { NavegadorComponent } from './components/navegador/navegador.component';
import { CompaniesReviewsComponent } from './components/companies-reviews/companies-reviews.component';
import { LocationsComponent } from './components/locations/locations.component';
import { LocationEditComponent } from './components/location-edit/location-edit.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SendEmailResetPasswordComponent } from './components/send-email-reset-password/send-email-reset-password.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
{
  path:'',
  component: LoginComponent
},
{
  path:'logout',
  component: LogoutComponent
},
{
  path:'panel',
  component: PanelComponent,
  canActivate: [AuthGuard]
},
{
  path:'busquedas',
  component: BusquedasComponent,
  canActivate: [AuthGuard]
},
{
  path:'visionados',
  component: VisionadosComponent,
  canActivate: [AuthGuard]
},
{
  path:'acciones',
  component: AccionesComponent,
  canActivate: [AuthGuard]
},
{
  path:'reputacion',
  component: ReputacionComponent,
  canActivate: [AuthGuard]
},
{
  path:'analisis',
  component: AnalisisComponent,
  canActivate: [AuthGuard]
},
{
  path:'mapas',
  component: MapComponent,
  canActivate: [AuthGuard]
},
{
  path:'historico',
  component: HistoricoComponent,
  canActivate: [AuthGuard]
},
{
  path: 'login/:token',
  component: LoginTokenComponent
},
{
  path: 'companies-reviews',
  component: CompaniesReviewsComponent,
  canActivate: [AuthGuard]
},
{
  path: 'locations',
  component: LocationsComponent,
  canActivate: [AuthGuard]
},
{ path: 'edit-location/:locationId',
  component: LocationEditComponent,
  canActivate: [AuthGuard] },
{
  path: 'send-email-reset-password',
  component: SendEmailResetPasswordComponent
},
{
  path: 'forgot-password/:token',
  component: ForgotPasswordComponent
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
